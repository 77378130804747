// extracted by mini-css-extract-plugin
export var address = "Contact-module--address--b895c";
export var column = "Contact-module--column--5f2e8";
export var contact = "Contact-module--contact--3bded";
export var contactContainer = "Contact-module--contact-container--2c503";
export var copy = "Contact-module--copy--cb423";
export var footer = "Contact-module--footer--1e149";
export var header = "Contact-module--header--86481";
export var item = "Contact-module--item--87c9d";
export var social = "Contact-module--social--06202";
export var socialContainer = "Contact-module--social-container--79d84";