import PropTypes from 'prop-types';
import React from 'react';
import { graphql } from 'gatsby';

import Instagram from '../svg/icons/instagram.svg';
import Location from '../svg/icons/location.svg';
import Mail from '../svg/icons/mail.svg';
import Clock from '../svg/icons/clock.svg';
import Layout from '../components/Layout';
import Hero from '../components/Hero';
import contactImg from '../images/contact.jpg';
import {
  footer,
  social,
  socialContainer,
  copy,
  header,
  contact,
  item,
} from '../css/Contact.module.css';

const Contact = ({ data }) => {
  const { wpWebdata } = data;
  const attributes = wpWebdata;

  return (
    <>
      <Layout
        pageTitle={attributes.shibuya.titleContact}
        copyright={attributes.shibuya.copyright}
        showFooter={false}
      >
        <Hero backgroundImage={contactImg} attributes={attributes} />
        <article className="article">
          <h1>{attributes.shibuya.contact}</h1>
          <section className={contact}>
            <section className={item}>
              <header className={header}>
                <Location width={22} height={22} />
                <h3>{attributes.shibuya.adress}</h3>
              </header>
              <a href="https://goo.gl/maps/7wTcdq9sKpjooPus7" target="_blank" rel="noreferrer">
                Smedestraat 27<br />
                2011 RE Haarlem
              </a>
            </section>
            <section className={item}>
              <header className={header}>
                <Mail width={22} height={22} />
                <h3>{attributes.shibuya.email}</h3>
              </header>
              <a href="mailto:info@shibuya.nl">info@shibuya.nl</a>
            </section>
            <section className={item}>
              <header className={header}>
                <Clock width={22} height={22} />
                <h3>{attributes.shibuya.hoursDefault}</h3>
              </header>
              <strong>{attributes.shibuya.monday}</strong>
              <p>{attributes.shibuya.hoursMonday}</p>
              <strong>{attributes.shibuya.tuesday}</strong>
              <p>{attributes.shibuya.hoursTuesday}</p>
              <strong>{attributes.shibuya.wednesday}</strong>
              <p>{attributes.shibuya.hoursWednesday}</p>
              <strong>{attributes.shibuya.thursday}</strong>
              <p>{attributes.shibuya.hoursThursday}</p>
              <strong>{attributes.shibuya.friday}</strong>
              <p>{attributes.shibuya.hoursFriday}</p>
              <strong>{attributes.shibuya.saturday}</strong>
              <p>{attributes.shibuya.hoursSaturday}</p>
              <strong>{attributes.shibuya.sunday}</strong>
              <p>{attributes.shibuya.hoursSunday}</p>
            </section>
          </section>
        </article>
      </Layout>
      <footer className={footer}>
        <section className={socialContainer}>
          <a href="https://www.instagram.com/shibuya_haarlem/" target="_blank" rel="noreferrer" className={social}>
            <Instagram width={18} height={18} />
            Instagram
          </a>
        </section>
        <p className={copy}>{attributes.shibuya.copyright}</p>
      </footer>
    </>
  );
};

export default Contact;

Contact.propTypes = {
  data: PropTypes.shape({
    wpWebdata: PropTypes.shape({
      title: PropTypes.string,
      shibuya: PropTypes.shape({
        contact: PropTypes.string,
        adress: PropTypes.string,
        email: PropTypes.string,
        hoursDefault: PropTypes.string,
        monday: PropTypes.string,
        hoursMonday: PropTypes.string,
        tuesday: PropTypes.string,
        hoursTuesday: PropTypes.string,
        wednesday: PropTypes.string,
        hoursWednesday: PropTypes.string,
        thursday: PropTypes.string,
        hoursThursday: PropTypes.string,
        friday: PropTypes.string,
        hoursFriday: PropTypes.string,
        saturday: PropTypes.string,
        hoursSaturday: PropTypes.string,
        sunday: PropTypes.string,
        hoursSunday: PropTypes.string,
        copyright: PropTypes.string,
        titleHome: PropTypes.string,
        titleAboutUs: PropTypes.string,
        titleMenu: PropTypes.string,
        titleReserve: PropTypes.string,
        titleFaq: PropTypes.string,
        titleContact: PropTypes.string,
      }),
    }),
  }).isRequired,
};

export const query = graphql`
query ($language: String!) {
  wpWebdata(title: {eq: $language}) {
    title
    shibuya {
      contact
      adress
      email
      hoursDefault
      monday
      hoursMonday
      tuesday
      hoursTuesday
      wednesday
      hoursWednesday
      thursday
      hoursThursday
      friday
      hoursFriday
      saturday
      hoursSaturday
      sunday
      hoursSunday
      copyright
      titleHome
      titleAboutUs
      titleMenu
      titleReserve
      titleFaq
      titleContact
    }
  }
}
`;
